<template>
  <v-dialog v-model="dialog" max-width="900" style="z-index:100;background-color: #fff !important;" @keydown.esc="cancel">
    <v-card>
      <v-row class="pa-0 ma-0">
        <v-col cols="12" md="7" order-sm="1">
          <v-subheader>Listado categorías</v-subheader>
          <v-divider class="mb-2"></v-divider>
          <v-data-table
            fixed-header
            :items="categorias"
            :headers="categoriasHeader"
            class="fixed-checkbox"
            item-key="idCategoria"
            :single-select="false"
            show-select
            :loading="loading"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [10, 25, 50, -1],
            }"
            checkbox-color="secondary"
          >
            <template v-slot:body.prepend="{ headers }">
              <table-filters
                :headers="headers"
                :items="categorias"
                v-model="inlineFilters"
              ></table-filters>
            </template>
  
            <template v-slot:item.nombreCategoria="{ item }">
              <v-chip :color="item.color" class="ml-1" outlined label>
                {{ item.nombreCategoria }}
              </v-chip>
            </template>
  
            <template v-slot:item.acciones="{ item }">
              <div class="d-flex">
                <v-btn
                  @click.stop="categoria = item"
                  small
                  rounded
                  color="secondary"
                >
                  <v-icon left>mdi-pencil</v-icon>
                  Editar
                </v-btn>
                <v-btn
                  @click.stop="deleteCategoria(item)"
                  small
                  class="ml-1"
                  rounded
                  color="error"
                >
                  <v-icon left>mdi-delete</v-icon>
                  Eliminar
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" md="5" order-sm="1">
          <v-subheader>Crear nueva categoría</v-subheader>
          <v-divider class="mb-2"></v-divider>
          <Nuevo
            @reload="getCategorias()"
            :key="keyHelper"
            :value="categoria"
          />
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-btn @click.stop="dialog = false" color="error" class="ms-auto me-5 mb-5">CERRAR</v-btn>
      </v-row>
    </v-card>
		<ConfirmDialog ref="confirm" />
  </v-dialog>
</template>

<script>
import { parseDate, perColumnFilter } from "@/utils/index.js";

export default {
  components: {
    Nuevo: () => import("./Nuevo.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
		ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      dialog: false,
      categorias: [],
      categoria: {},
      inlineFilters: {},
      keyHelper: 0,
      loading: false,
      categoriasHeader: [
        {
          text: "Nombre",
          value: "nombreCategoria",
          cellClass: "pa-1 text-no-wrap font-weight-bold",
        },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
          filterable: false,
        },
      ].map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-1 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),
    };
  },
  watch: {
    categoria() {
      this.keyHelper++;
    },
  },
  mounted() {
    this.getCategorias();
  },
  methods: {
    open(){
      this.dialog = true
    },
    parseDate,
    async getCategorias() {
      this.keyHelper++;
      this.categoria = {};
      const { data } = await axios({
        url: "/categorias_equipo",
      });
      this.categorias = data;
      this.loading = false;
    },
    async deleteCategoria({ idCategoriaEquipo, nombreCategoria }) {
			const confirmation = await this.$refs.confirm.open(
				`Categoría: ${nombreCategoria}`,
        `¿Estás seguro de que quieres eliminar esta categoria?`,
        { color: "error" }
      );
      if (!confirmation) return;

      const { data } = await axios({
        method: "DELETE",
        url: "/categorias_equipo/" + idCategoriaEquipo,
      });
      this.getCategorias();
    },
  },
};
</script>

<style>
</style>